import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import FaqItem from 'src/modules/Faq/FaqItem';
import Heading from 'src/components/Heading';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';

const FaqWrapper = styled('div')`
    width: 100%;
    color: ${theme.color.black};

    ${theme.spacing.mobile._12('padding')};
    ${theme.spacing.mobile._40('padding-top')};
    ${theme.spacing.mobile._40('padding-bottom')};

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.tablet._64('padding-top')};
    ${theme.spacing.tablet._64('padding-bottom')};

    ${theme.spacing.desktop._32('padding')};
    ${theme.spacing.desktop._64('padding-top')};
    ${theme.spacing.desktop._88('padding-bottom')};
`;

const FaqTextWrapper = styled('div')`
    width: 100%;
`;

const FaqHeading = styled(Heading)`
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    ${theme.fontSize.sans.mobile._32};
    ${theme.fontSize.sans.tablet._32};
    ${theme.fontSize.sans.desktop._40};

    ${theme.spacing.mobile._12('margin-top')};
    ${theme.spacing.tablet._0('margin-top')};
    ${theme.spacing.desktop._12('margin-top')};

    ${theme.spacing.mobile._16('padding-bottom')};
    ${theme.spacing.tablet._16('padding-bottom')};
    ${theme.spacing.desktop._16('padding-bottom')};
`;

const FaqText = styled(Text)`
    line-height: 160%;

    ${above.tablet_s} {
        width: 50%;
    }

    ${theme.spacing.mobile._40('padding-bottom')};
    ${theme.spacing.tablet._64('padding-bottom')};
    ${theme.spacing.desktop._64('padding-bottom')};
`;

const FaqList = styled('ul')`
    list-style: none;
    padding: 0;
    margin: 0;

    ${above.tablet_s} {
        border-top: 1px solid ${theme.color.black};
    }

    ${below.tablet_s} {
        border-bottom: 1px solid ${theme.color.black};
    }
`;

const Faq = data => {
    const faqData = data.data;

    return (
        <FaqWrapper>
            <FaqTextWrapper>
                <FaqHeading as="h1">{faqData.heading}</FaqHeading>
                <FaqText>{faqData.text}</FaqText>
            </FaqTextWrapper>
            <FaqList>
                {faqData.questions.map((question, index) => {
                    return (
                        <li key={index}>
                            <FaqItem title={question.text} text={question.answer} />
                        </li>
                    );
                })}
            </FaqList>
        </FaqWrapper>
    );
};

Faq.propTypes = {
    data: PropTypes.shape({
        data: PropTypes.shape({
            heading: PropTypes.string,
            questions: PropTypes.arrayOf(
                PropTypes.shape({
                    answer: PropTypes.string,
                    text: PropTypes.string,
                })
            ),
            text: PropTypes.string,
        }),
    }).isRequired,
};

export default Faq;
